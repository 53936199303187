.push-bar {
    margin-inline: 0.5rem;
    padding: 1rem;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}

.smartphone {
    position: relative;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
    width: 300px;
    height: 69vh;
    padding-block: 1rem;
    background: url("https://wallpaper.dog/large/11020803.jpg");
    background-size: cover;
}

.p-0 {
    padding: 0 !important;
}

.h-auto {
    height: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

p {
    margin: 0 !important;
}

.action-button {
    cursor: pointer;
    display: grid;
    place-items: center;
    padding-block: 1rem;
    margin-top: 0.2rem;
}

img {
    max-width: 100% !important;
}